.ContractList{
    display: flex;
    flex-direction: column;
    flex: 8;
    align-content: flex-start;
    width: 80%;
    overflow-y: auto;

}
.showVertragContainer{
    display: flex;
    flex-direction: column;
    flex: 1;
    align-content: flex-start;
    width: 100%;
    overflow-y: auto;
}
.mailContainer{
    display: flex;
    flex-direction: column;
    flex: 8;
    align-content: flex-start;
    width: 95%;
    padding-left: 5px;
    overflow-y: auto;
}
.pdfContainer{
    display: flex;
    flex: 8;
    width:100%;

}
.vertragHtml{
    display: flex;
    flex: 8;
    width:100%;
    height:100%;
    overflow-y: auto;
}
.SendButtonContainer{
    display: flex;
    flex-direction: row;
    flex:2;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
    margin-top: 10px;
    
    
    width:100%;
}
.mailInput{    
    width: 100%;
    height: 100px;
    padding: 20px;
    font-size: 20px;
    border: 1px solid #ccc;
    box-sizing: border-box;
    border-radius: 10px;
    margin-bottom: 50px;
}
.inputContainer{
    display: flex;
    flex-direction: column;
    flex: 8;
    align-content: flex-start;
    width: 100%;
    overflow-y: auto;
}


.pdfDoc{
    width:100%;
    height:100%;

}

.matrixheaders{
    font-size: 20px;
    margin-top: 20px;
    font-weight: bold;
}

.QestionContainer{
    display: flex;
    flex-direction: column;
    flex: 8;
    align-items: flex-start;
    justify-content: top;
    width: 95%;
   
    overflow-y: auto;
}
.QuestionText{
    display: flex;
    flex-direction: column;
    text-align: flex-start;   
    white-space: pre-line;
    width: 100%;
    font-size: 18px;
    margin-top: 5px;
}

.AntwortButtonContainer{
    display: flex;
    
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 10px;    
    
    padding: 20px;
    margin-bottom: 20px;
    width: 100%;
                
}
.AntwortModalContainer{
    display: flex;
    
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;    
    margin-left: -20px;
    padding: 20px;
    
    width: 100%;
}
.OverviewText{
    display: flex;
   
    flex-direction: column;
    width: 100%;
    font-size: 20px;
    white-space: pre-line;
}



.FooterContainer{
    display: flex;
    flex:1;
    flex-direction: row;
    justify-content: space-between;  
    margin-top: 20px;
    width: 100%;
}
.FooterButton{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 10px;    
    background-color: white;   
    padding: 20px;
    margin-bottom: 20px;
    width: 100%;
}

.footerBtnPositiv{
    background-color: #0081d1;
    color: white;
    border-radius: 4px;
    border: 0;
    outline: 0;
    padding-right: 15px;
    padding-left: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 15px;
    text-transform: uppercase;
    min-width: 130px;
    min-height: 39px;
    
}
.footerBtnNegativ{
    background-color: #e42d2d;
    color: white;
    border-radius: 4px;
    border: 0;
    outline: 0;
    padding-right: 15px;
    padding-left: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 15px;
    text-transform: uppercase;
    min-width: 130px;
}
.button-hidden{
    display: none !important;
}