.AppContainer {
  display: flex; 
  flex:1; 
  flex-direction: column;
  align-items: center;
  height: 600px;
  width: 100%;
  background-color: #f5f5f5;
  font-family: Arial, Helvetica, sans-serif;
}
h1{
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
}
h3{
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
}
p{
  font-size: 12px;
  margin-bottom: 5px;
}
.ButtonContainer {
  display: flex;
  
  flex-direction: row;
  justify-content: space-between;  
  margin-top: 20px;

  width: 80%;
}
.ButtonCard{
    border-radius: 10px;    
    background-color: white;   
    padding: 10px;
    margin-bottom: 20px;
    max-width: 40%;
   
}
.ButtonCardHeaderIconContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}
.ButtonCardHeaderIcon{
    display: flex;
    justify-content: center;
    align-items: center;
}
.ButtonCardNewIcon{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-left: 10px;
}

.ButtonCardImage{
    display: flex;
    justify-content: center;
    align-items: center;
    
    margin-right: 15px;
}
.ButtonCardImage img{
    width: 50px;
    height: 50px;
}
.ButtonCardText{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.headertxt{
  display: flex;
  align-content: flex-start;
  flex-direction: column;
  width: 80%;
  font-size: 15px;
}
.AppHeader{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding-top: 10px;
  width: 100%;
  margin-bottom: 10px;
}
.AppHeaderLeft{
  padding: 20px;
}
.AppHeaderRight{
  padding: 20px;
}
.hide-element{
  display: none;
}
.disabled{
  opacity: 0.5;
}