.ContractList{
    display: flex;
    flex-direction: column;
    flex: 8;
    align-content: flex-start;
    width: 80%;
    overflow-y: auto;

}
.ContractCard{
    display: flex;
    align-content: flex-start;
   
    border-radius: 10px;    
    background-color: white;   
    padding: 20px;
    margin-bottom: 20px;
  
}
.ContractCardText{
    display: flex;
    flex: 5;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: center;
}
.ButtonStatusDone{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex:1;
   
}
.ButtonStatusUnfinished{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex:1;
   
}

.hideIcon{
    display: none;
}

.ContractCardContainer{
    display: flex;    
    flex-direction: column;  
    height: 100%;
}
.FooterContainer{
    display: flex;
    flex:1;
    flex-direction: row;
    justify-content: space-between;  
    margin-top: 20px;
    width: 80%;
}
.FooterButton{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 10px;    
    background-color: white;   
    padding: 20px;
    margin-bottom: 20px;
    width: 100%;
}
.disabled{   
    opacity: 0.4;
}
